import * as React from "react";
import AppHeader from "../AppHeaderContainer";
import { userSettingConstants, hideHeaderAndSideMenu } from "../../helper/Constants";
import Notification from "../../helper/NotificationContainer";
import { IEExplorerBlocker } from "../../IEExplorerBlocker";
import { MultiConcurrentUser } from "../../MultiConcurrentUser";
import { handleResponse } from "../../../store/Library";
import SignalRWebSocket from "../../SignalRwebSocketContainer";
import LastLoginModal from "../../LastLoginModal";
import SecurityModal from "../../SecurityModal";
import { TYPES } from "../../../Startup/types";
import { container } from "../../../Startup/inversify.config";
import { ISessionLocalStore } from "../../../Core/Utilities/SessionStore";
import ChangeDefault from "../../ChangeDefaultContainer";
import { ILogger } from "../../../Logger/Logger";
import { LoggerFactory } from "../../../Logger/LoggerFactory";
import { UserAutoLogoutModal } from "../../UserAutoLogoutModal";
import { API_BASE_URL, SSSUITE_API_BASE_URL } from "../../../utils/constants";
import { getAccessToken, signoutRedirect } from "../../../oidcClient/userService";
import Layout from "@sssuite-component-ui/ss-suite-layout";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { headerConfig, initialSideMenuConfig, initialWidgetDropdownData, sideMenuConfig } from "./LayoutConfig";
import { ProductType } from "../../../Core/ViewModels/Company/CompanyViewModel";
import { ISectionData } from "@sssuite-component-ui/ss-suite-layout/dist/Header/NavigationWidget/NavigationWidget";
import * as LayoutStore from "../../../store/Navigation/LayoutStore";
import { ISideMenu, IProductData, IPendoData } from "../../../Core/ViewModels/Common/Layout";
import { ISideMenuProps } from "@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu";
import { IUserPreviligeState, LogoutCause } from "../../../store/auth/reducer";
import { createForethoughtCookie, getcookie, resetCookie } from "../../helper/HelperFunctions";
import { resetUserCache } from "../../../store/auth/auth.apis";
import * as userStore from "../../../store/userManagement/UserStore";
import * as UserProfileStore from "../../../store/userManagement/UserProfileStore";
import { IAuthState } from "../../../store/auth/reducer";
import { IUserProfile } from "../profile/ProfileObjects";
import { ICompanyData } from "../../../store/company/CompanyStore";
import * as SuiteTaxSoftwareSettingStore from "../../../store/company/SuiteTaxSoftwareSettingStore";
import { SessionTimeout } from "@sssuite-component-ui/session-timeout";
import { Forethought } from "@sssuite-component-ui/forethought-ai-chatbot";
import * as authAction from "src/store/auth/actions";
const SessionStore = container.get<ISessionLocalStore>(TYPES.ISessionStore);

declare global {
    interface Window {
        Variables: any;
        pendo: any;
    }
}

const WarningVisibleMinutes = 1;
export interface ILayoutProps {
    children?: React.ReactNode;
    userPrivilege: IUserPreviligeState;
}

type LayOutProps = ILayoutProps & {
    leftMenuData: ISideMenu;
    accessibleProductData: IProductData;
    userResource: userStore.IUserResources;
    userProfile: IUserProfile;
    auth: IAuthState;
    companyData: ICompanyData;
    loggedOut: () => void;
    pendoData: IPendoData;
} & typeof LayoutStore.actionCreators &
    typeof userStore.actionCreators &
    typeof UserProfileStore.actionCreators &
    typeof SuiteTaxSoftwareSettingStore.actionCreators &
    typeof authAction.actionCreators;

interface MenuToggler {
    toggleMenu(): void;
}

interface ILayoutState {
    isVisible: boolean;
    showTimeout: boolean;
    isConcurrentUser: boolean;
    tabIdentifier: any;
    sessionExpiredModal: boolean;
    showAutoLogoutModal: boolean;
    userAutoLogoutCause: LogoutCause;
    logger: ILogger;
    sessionIdleMinutes: number;

    showModal: {
        showProfile: boolean;
        showLoginHistory: boolean;
        showMySettings: boolean;
        showMyDownloads: boolean;
        showLogout: boolean;
        showSupport: boolean;
    };
    showNotificationPopover: boolean;
    leftMenuList: ISideMenuProps;
    widgetDropdownData: ISectionData[];
    suiteUrl: string;
}

export class LayoutContainer extends React.Component<LayOutProps, ILayoutState> {
    private sessionRenew: any;
    private sessionExpired: any;
    private closePopoverRef: any;

    constructor(props: LayOutProps) {
        super(props);

        this.state = {
            isVisible: true,
            sessionExpiredModal: false,
            showAutoLogoutModal: false,
            userAutoLogoutCause: LogoutCause.None,
            logger: new LoggerFactory().getTelemetryLogger(),
            sessionIdleMinutes: 0,

            showModal: {
                showProfile: false,
                showLoginHistory: false,
                showMySettings: false,
                showMyDownloads: false,
                showLogout: false,
                showSupport: false
            },
            showNotificationPopover: false,
            leftMenuList: initialSideMenuConfig,
            widgetDropdownData: initialWidgetDropdownData,
            suiteUrl: ""
        };
    }

    UNSAFE_componentWillMount(): void {
        this.props.requestUserProfile(true);
        this.props.requestUserAccessibleProducts();
        this.props.requestUserRoles();
        this.fetchWidgetDropdownData();
        this.fetchSuiteUrl();
        this.props.requestUserResourceIds();
        this.props.requestTaxSoftwareSetting();
        this.props.requestPendoData();
    }

    UNSAFE_componentWillReceiveProps(nextProps: LayOutProps) {
        this.setState({
            leftMenuList: sideMenuConfig(nextProps.leftMenuData.leftMenu, !nextProps.accessibleProductData.isAccessible)
        });
        if (this.props.userPrivilege?.userPrivilegesChanged) {
            if (!this.state.showAutoLogoutModal) {
                this.setState({
                    showAutoLogoutModal: true,
                    userAutoLogoutCause: this.props.userPrivilege?.logoutCause
                });
            }
        }

        if (this.props.userPrivilege?.isResetCacheApiCalled && this.state.sessionIdleMinutes == 0) {
            this.sessionRenew = setInterval(() => {
                this.renewSession();
            }, 1000 * 60 * 20);
            this.setSessionIdleTime();
            this.setIntervalSessionExpiredCheck();
        }
    }

    componentDidMount() {
        if (!this.props.userPrivilege.isResetCacheApiCalled && getcookie("userAutoLogout") !== "1") {
            resetUserCache();
        }
        this.injectPendoScript();
    }

    componentDidUpdate(prevProps: Readonly<LayOutProps>, prevState: Readonly<ILayoutState>, snapshot?: any): void {
        if (prevProps.pendoData !== this.props.pendoData) {
            this.injectPendoScript();
        }
    }

    componentWillUnmount() {
        clearInterval(this.sessionRenew);
        clearInterval(this.sessionExpired);
    }

    private injectPendoScript = () => {
        const {
            company_id,
            company_name,
            user_id,
            given_name: firstName,
            family_name: lastName,
            email
        } = this.props.auth.user?.profile;
        const isTestCompany = this.props.pendoData?.isTestCompany ?? false;
        const subscription = this.props.pendoData?.subscription ?? "";

        if (!company_id || !company_name || !user_id || !firstName || !lastName || !email) {
            console.log("User profile not available yet");
            return;
        }

        const apiKey = process.env.REACT_APP_PENDO_API_KEY;
        const scriptId = "pendo-script";

        const existingScript = document.getElementById(scriptId);
        if (existingScript) {
            existingScript.remove();
        }

        (function (p: any, e: any, n: any, d: any, o: any) {
            var v: any, w: any, x: any, y: any, z: any;
            o = p[d] = p[d] || {};
            o._q = o._q || [];
            v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
            for (w = 0, x = v.length; w < x; ++w)
                (function (m) {
                    o[m] =
                        o[m] ||
                        function () {
                            o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
                        };
                })(v[w]);
            y = e.createElement(n);
            y.async = !0;
            y.id = scriptId;
            y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
            z = e.getElementsByTagName(n)[0];
            z.parentNode.insertBefore(y, z);
        })(window, document, "script", "pendo", "");

        window.pendo?.initialize({
            visitor: {
                Id: `${company_id}-${user_id}-${email}`,
                Email: email,
                FirstName: firstName,
                LastName: lastName
            },
            account: {
                Id: company_id,
                AccountName: company_name,
                FirmType: isTestCompany ? 'Internal' : 'Live',
			    Subscription: subscription,
            }
        });
    };

    private setIntervalSessionExpiredCheck = () => {
        this.sessionExpired = setInterval(() => {
            if (!getAccessToken()) {
                SessionStore.remove("isVisited");
                this.setState({ sessionExpiredModal: true });
            }
        }, 1000);
    };

    private toggleShowModal = (prop: string) => {
        switch (prop) {
            case userSettingConstants.profile:
                this.setState({ showModal: { ...this.state.showModal, showProfile: !this.state.showModal.showProfile } });
                return;
            case userSettingConstants.loginHistory:
                this.setState({
                    showModal: { ...this.state.showModal, showLoginHistory: !this.state.showModal.showLoginHistory }
                });
                return;
            case userSettingConstants.mySettings:
                this.setState({ showModal: { ...this.state.showModal, showMySettings: !this.state.showModal.showMySettings } });
                return;
            case userSettingConstants.myDownloads:
                this.setState({ showModal: { ...this.state.showModal, showMyDownloads: !this.state.showModal.showMyDownloads } });
                return;
            case userSettingConstants.logout:
                this.setState({ showModal: { ...this.state.showModal, showLogout: !this.state.showModal.showLogout } });
                return;
            case userSettingConstants.support:
                this.setState({ showModal: { ...this.state.showModal, showSupport: !this.state.showModal.showSupport } });
                return;

            default:
                return;
        }
    };

    private toggleNotificationPopover = (prop: boolean) => {
        this.setState({ showNotificationPopover: prop });
    };

    private assignRef = (reference: any) => {
        this.closePopoverRef = reference;
    };

    private hideHeaderAndSideMenuForCurentURL = () => {
        if (hideHeaderAndSideMenu.includes(window?.location?.pathname)) return true;
        return false;
    };

    closeUserPermissionChangePopup = () => {
        this.setState({ showAutoLogoutModal: false });
    };

    public render() {
        if (navigator.userAgent.indexOf("Trident") > -1) {
            return <IEExplorerBlocker />;
        } else {
            return (
                <div>
                    <AppHeader
                        showModal={this.state.showModal}
                        toggleMenu={this.toggleShowModal}
                        closePopoverRef={this.closePopoverRef}
                        showNotificationPopover={this.state.showNotificationPopover}
                        toggleNotificationPopover={this.toggleNotificationPopover}
                    />
                    {this.props.userPrivilege.isResetCacheApiCalled && (
                        <>
                            <Notification />
                        </>
                    )}
                    <Layout
                        headerProps={headerConfig({
                            toggleShowModal: this.toggleShowModal,
                            assignRef: this.assignRef,
                            toggleNotificationPopover: this.toggleNotificationPopover,
                            widgetDropdownData: this.state.widgetDropdownData,
                            suiteUrl: this.state.suiteUrl,
                            resourceIds: this.props.userResource.resourceIds,
                            auth: this.props.auth,
                            userProfile: this.props.userProfile,
                            companyData: this.props.companyData,
                            hideHeader: this.hideHeaderAndSideMenuForCurentURL()
                        })}
                        sideMenuProps={this.hideHeaderAndSideMenuForCurentURL() ? undefined : this.state.leftMenuList}
                    >
                        <div id="content-wrapper" className="px-3 container-fluid layout-container">
                            {this.props.children}
                        </div>
                    </Layout>

                    <SecurityModal openModal={this.state.sessionExpiredModal} />
                    <UserAutoLogoutModal
                        show={this.state.showAutoLogoutModal}
                        logoutCause={this.state.userAutoLogoutCause}
                        closeUserPermissionChangePopup={this.closeUserPermissionChangePopup}
                    />
                    <SessionTimeout
                        deviceId={this.props.auth.user.profile.device_id}
                        onExternalLogout={() => createForethoughtCookie("isForethoughtWidgetVisible", "false")}
                        logout={signoutRedirect}
                        sessionIdleMinutes={this.state.sessionIdleMinutes}
                        warningVisibleMinutes={WarningVisibleMinutes}
                        currentTabLoggedOutShow={this.props.auth.loggedOut}
                        setCurrentTabLoggedOutShow={() => {
                            this.props.loggedOut();
                        }}
                    />
                    <MultiConcurrentUser showModal={this.state.isConcurrentUser} />
                    <SignalRWebSocket />
                    <LastLoginModal />
                    <ChangeDefault />
                    <Forethought offsetY="40px" positionX="right" positionY="bottom" hideWidgetIframeOnLoad="true" />
                </div>
            );
        }
    }

    private renewSession = () => {
        fetch(`${API_BASE_URL}api/MyAccount/StayAliveAsync`, {
            method: "GET",
            credentials: "include"
        })
            .then(handleResponse)
            .catch((error: any) => {
                console.log(error);
            });
    };

    private setSessionIdleTime = () => {
        fetch(`${API_BASE_URL}api/KeyVault/SessionTimeOutMinutes`, {
            method: "GET",
            credentials: "include"
        })
            .then(handleResponse)
            .then((data) => {
                if (data) {
                    this.setState({ sessionIdleMinutes: data });
                }
            })
            .catch((error) => {
                if (error.status != 512) {
                    this.setState({ sessionIdleMinutes: 20 });
                }
            });
    };

    private fetchWidgetDropdownData = () => {
        fetch(SSSUITE_API_BASE_URL + `api/menu/widget-menu/${ProductType.Returns}`, {
            method: "GET",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json() as Promise<ISectionData[]>)
            .then((data) => {
                this.setState({ widgetDropdownData: data });
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    private fetchSuiteUrl = () => {
        fetch(SSSUITE_API_BASE_URL + `api/helper/suite-url`, {
            method: "GET",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.json() as Promise<string>)
            .then((data) => {
                this.setState({ suiteUrl: data });
            })
            .catch(function (error) {
                console.log(error);
            });
    };
}
